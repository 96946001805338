import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { Container, Content, mobileThresholdPixels } from '../components/home/v2/styledComponents';
import Header from '../components/home/v3/Header';
import BenefitsBanner from '../components/home/v3/BenefitsBanner';
import ACouturierAtHome from '../components/home/v3/ACouturierAtHome';
import FeedbacksBannerAds from '../components/home/v3/FeedbacksBannerAds';
import ReferrerBanner from '../components/home/ReferrerBanner';
import FAQAds from '../components/home/v3/FAQAds';
import MenuBar from '../components/home/MenuBar';
import PartnersPager from '../components/home/v3/PartnersPager';
import FootNote from '../components/LandingPage/FootNote';
import FooterSEOLinks from '../components/LandingPage/FooterSEOLinks';
import Footer from '../components/home/v3/Footer';
import Layout from '../layouts/index';

import {
  getFootNoteTitle, getBreadCrumb, getPostion, getCouturierAltTag, getAlterationAltTagLocality,
} from './LandingLocalityFormatter';

const StyledContainer = styled(Container)`
  padding-top: 70px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: 43px;
  }
`;

class LandingLocalityPageTemplate extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    const { data: { landingsLocalitiesJson }, referrer } = this.props;
    const { slug, city, locality, arrondissement, paragraph, links } = landingsLocalitiesJson;
    const prefilledSteps = { selectedCategory: 'clothes', selectedTypeOfWork: 'alteration' };
    return (
      <Layout routeSlug={slug}>
        <StyledContainer>
          <Content full>
            {referrer && <ReferrerBanner referrer={referrer} />}
            <MenuBar />
            <Header
              title={`Vos retouches de vêtements à domicile - ${getPostion({ locality, city, arrondissement })}`}
              hideNote
              smallTitle
              titleLeft
              prefilledSteps={prefilledSteps}
            />
            <BenefitsBanner altTag={getAlterationAltTagLocality({ locality, city, arrondissement })} />
            <ACouturierAtHome
              prefilledSteps={prefilledSteps}
              couturierAltTag={getCouturierAltTag({ locality, city, arrondissement })}
            />
            <FeedbacksBannerAds />
            <FAQAds prefilledSteps={prefilledSteps} city={city} isSEO />
            <PartnersPager />
            <FootNote
              title={getFootNoteTitle({ locality, city, arrondissement })}
              paragraph={paragraph}
              links={links}
              itemListElement={getBreadCrumb({ locality, city, arrondissement })}
            />
            <FooterSEOLinks city={city} locality={locality} arrondissement={arrondissement} />
            <Footer />
          </Content>
        </StyledContainer>
      </Layout>
    );
  }
}

LandingLocalityPageTemplate.propTypes = {
  data: PropTypes.shape({
    landingsLocalitiesJson: PropTypes.shape({}).isRequired,
  }).isRequired,
  referrer: PropTypes.shape({}),
};

LandingLocalityPageTemplate.defaultProps = {
  referrer: null,
};

export default LandingLocalityPageTemplate;

export const landingLocalityPageQuery = graphql`
query landingsLocalitiesQuery($slug: String!) {
  landingsLocalitiesJson(slug: { eq: $slug }) {
    slug
    city
    locality
    zipcode
    arrondissement
    paragraph
    links
  }
}`;
