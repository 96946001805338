import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from './styledComponents';
import Feedback from './Feedback';
import photoDamien from '../../../assets/feedbacks/damien.png';
import photoMiguel from '../../../assets/feedbacks/miguel.png';
import photoThomas from '../../../assets/feedbacks/thomas.png';
import photoEmmanuelle from '../../../assets/feedbacks/emmanuelle.png';

const Background = styled.div`
  width: 100vw;
  padding: 70px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  background-color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    height: unset;
    padding: 20px 0px;
  }
`;

const FeedbacksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100vw;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const feedbacksCloth = [
  {
    photo: photoMiguel,
    name: 'Miguel',
    jobDescription: 'a fait retoucher ses costumes par Khadija',
    // eslint-disable-next-line
    feedback: 'Le concept est vraiment top et les prix très attractifs comparés aux tailleurs/retouches habituels ! Vive Tilli ;-)',
    rating: 5,
  },
  {
    photo: photoDamien,
    name: 'Damien',
    jobDescription: 'a fait remailler des pulls par Alexandra',
    // eslint-disable-next-line
    feedback: 'L’expérience utilisateur sur le produit est fluide et claire. Ma couturière était compétente et gentille. Tout s\'est déroulé comme prévu. Super service bravo !',
    rating: 5,
  },
];

const feedbacksCurtains = [
  {
    photo: photoEmmanuelle,
    name: 'Emmanuelle',
    jobDescription: 'a fait retoucher ses rideaux en longueur',
    // eslint-disable-next-line
    feedback: '“Super service et super couturier. J\'ai vraiment gagné du temps et le résultat est top. On a passé un super moment.”',
    rating: 5,
  },
  {
    photo: photoThomas,
    name: 'Thomas',
    jobDescription: 'a fait des ourlets sur ses rideaux',
    // eslint-disable-next-line
    feedback: '“Très bonne expérience. J\'avais 6 rideaux à retoucher. Beaucoup de sérieux, tout s’est fait dans les délais, et avec professionnalisme.”',
    rating: 5,
  },
];

const FeedbacksBanner = ({ curtainsVersion, customerFeedbacks }) => (
  <Background>
    {customerFeedbacks
      ? (
        <FeedbacksContainer>
          {customerFeedbacks.map(customerFeedback => (
            <Feedback
              key={customerFeedback.feedback}
              feedback={{ ...customerFeedback, photo: customerFeedback.photo.publicURL }}
            />
          ))}
        </FeedbacksContainer>
      ) : (
        <FeedbacksContainer>
          <Feedback feedback={curtainsVersion ? feedbacksCurtains[0] : feedbacksCloth[0]} />
          <Feedback feedback={curtainsVersion ? feedbacksCurtains[1] : feedbacksCloth[1]} />
        </FeedbacksContainer>
      )}
  </Background>
);

FeedbacksBanner.propTypes = {
  curtainsVersion: PropTypes.bool,
  customerFeedbacks: PropTypes.arrayOf(PropTypes.shape({
    photo: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
    name: PropTypes.string,
    rating: PropTypes.number,
    jobDescription: PropTypes.string,
    feedback: PropTypes.string,
  })),
};

FeedbacksBanner.defaultProps = {
  curtainsVersion: false,
  customerFeedbacks: undefined,
};

export default FeedbacksBanner;
