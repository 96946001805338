export function getHeaderPhotoItems({ city, locality, arrondissement } = {}) {
  if (arrondissement) {
    return [{
      title: `Vos retouches de vêtements à domicile - ${city} ${arrondissement}`,
      subTitle: 'Prenez rendez-vous avec votre couturier à domicile, \n7 jours sur 7 de 7h00 à 22h00',
      imageAlt: `Une couturière à domicile à ${city} ${arrondissement} effectuant une retouche`,
      isNavy: true,
    }];
  }
  if (locality) {
    return [{
      title: `Vos retouches de vêtements à domicile - ${locality}`,
      subTitle: 'Prenez rendez-vous avec votre couturier à domicile, \n7 jours sur 7 de 7h00 à 22h00',
      imageAlt: `Une couturière à domicile à ${locality} dans son atelier de retouches de couture`,
      isNavy: true,
    }];
  }
  return [{
    title: `Vos retouches de vêtements à domicile - ${city}`,
    subTitle: 'Prenez rendez-vous avec votre couturier à domicile, \n7 jours sur 7 de 7h00 à 22h00',
    imageAlt: `Une couturière à domicile à ${city} dans son atelier de retouches de couture`,
    isNavy: true,
  }];
}

export function getPostion({ city, locality, arrondissement } = {}) {
  if (arrondissement) return `${city} ${arrondissement}`;
  if (locality) return `${locality}`;
  return `${city}`;
}

export function getFootNoteTitle({ city, locality, arrondissement } = {}) {
  // eslint-disable-next-line max-len
  return `Pourquoi faire appel à un couturier à domicile \npour vos retouches de vêtements à ${getPostion({ city, locality, arrondissement })} ?`;
}

export function getBreadCrumb({ city, locality, arrondissement } = {}) {
  if (arrondissement) {
    const arrondissement2digits = (`0${arrondissement}`).slice(-2);
    return [
      { name: 'Accueil', item: '/' },
      { name: 'Retouche Vêtement', item: '/retouche-vetement/' },
      { name: city, item: `/retouche-vetement/${city}/`.toLowerCase() },
      { name: `${city} ${arrondissement}`,
        item: `/retouche-vetement/${city}/${city}-${arrondissement2digits}/`.toLowerCase() },
    ];
  }
  if (locality) {
    return [
      {
        name: 'Accueil',
        item: '/',
      },
      {
        name: 'Retouche Vêtement',
        item: '/retouche-vetement/',
      },
      {
        name: city,
        item: `/retouche-vetement/${city}/`.toLowerCase(),
      },
      {
        name: locality,
        item: `/retouche-vetement/${city}/${locality}/`.toLowerCase(),
      },
    ];
  }
  return [
    {
      name: 'Accueil',
      item: '/',
    },
    {
      name: 'Retouche Vêtement',
      item: '/retouche-vetement/',
    },
    {
      name: city,
      item: `/retouche-vetement/${city}/`.toLowerCase(),
    },
  ];
}

export function getCouturierAltTag({ city, locality, arrondissement } = {}) {
  if (arrondissement) return `couturière ${city} ${arrondissement}`;
  if (locality) return `couturière à domicile ${locality}`;
  return `couturière à domicile ${city}`;
}

export function getAlterationAltTagLocality({ city, locality, arrondissement } = {}) {
  if (arrondissement) return `retouche vêtement ${city} ${arrondissement}`;
  if (locality) return `retouche vêtement ${locality}`;
  return `retouche vêtement ${city}`;
}

export function getAlterationAltTagMainLanding(slug) {
  if (slug === 'HomePageClothesAlteration') return 'retouche vêtement';
  if (slug === 'HomePageCurtainsAlterations') return 'retouche rideaux voilage';
  return 'retouche couture avec Tilli';
}
