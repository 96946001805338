import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import routesMap from '../../Routes';
import withAppContext from '../../withAppContext';
import Breadcrumb from './Breadcrumb';
import Dots from '../../components/home/v3/Dots';
import { margins, Column } from '../home/v2/styledComponents';
import { fontSizes, mobileThresholdPixels, colors, Title2 } from '../../components/home/v3/styledComponents';
import { toFormattedText, toFormattedLinkedText } from '../../services/formatting';

const Container = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 35px 15px 15px;
    margin: 0px;
  }
`;

const TextContainer = styled.p`
  font-family: Roboto;
  font-size: ${fontSizes.s};
  margin: auto;
  text-align: center;
  color: ${colors.navy};
  width: 900px;
  ${props => !props.withoutTitle && `
    margin-bottom: ${margins.m};
    text-align: justify;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const TitleFAQ = styled(TextContainer.withComponent('h3'))`
  font-weight: 500;
`;

const StyledLink = styled(TextContainer.withComponent('span'))``;

function renderFAQ() {
  return (
    <div itemProp="mainEntity" itemScope itemType="https://schema.org/FAQPage">
      <div itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
        <TitleFAQ itemProp="name">Comment se déroule le rendez-vous avec le couturier ?</TitleFAQ>
        <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
          <TextContainer itemProp="text" style={{ textAlign: 'justify' }}>
            Notre couturier se déplace chez vous. Prenez le temps d’essayer les vêtements
            et de bien expliquer ce que vous souhaitez. Notre couturier prend les marques
            et mesures nécessaires, et repart ensuite avec vos vêtements pour réaliser les retouches dans son atelier.
            {' '}
            <Link
              to={routesMap.Step1.url}
              title="rendez-vous couturier"
            >
              <StyledLink>Prendre rendez-vous</StyledLink>
            </Link>
          </TextContainer>
        </div>
      </div>
      <div itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
        <TitleFAQ itemProp="name">Quels sont les horaires de vos couturiers ?</TitleFAQ>
        <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
          <TextContainer itemProp="text" style={{ textAlign: 'justify' }}>
            Nos couturiers peuvent venir chez vous de 9h à 22h toute la semaine et y compris
            le week-end ! Quand on vous dit que Tilli vous simplifie la vie ?{' '}
            <Link
              to={routesMap.Step1.url}
              title="rendez-vous couturier"
            >
              <StyledLink>Prendre rendez-vous</StyledLink>
            </Link>
          </TextContainer>
        </div>
      </div>
      <div itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
        <TitleFAQ itemProp="name">Quels sont les délais de livraison une fois le produit retouché ?</TitleFAQ>
        <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
          <TextContainer itemProp="text" style={{ textAlign: 'justify' }}>
            En moyenne 3 jours. Mais si la demande est forte ou les retouches demandées plus complexes,
            ça peut prendre un peu plus de temps. Dans ce cas, le couturier vous prévient évidemment.
            {' '}
            <Link
              to={routesMap.Step1.url}
              title="rendez-vous couturier"
            >
              <StyledLink>Prendre rendez-vous</StyledLink>
            </Link>
          </TextContainer>
        </div>
      </div>
    </div>
  );
}

const FootNote = ({
  title, paragraph, links, itemListElement, breadcrumbIntro, breadcrumbIntroLinks, context: { isMobile },
  showFAQ,
}) => (
  <Container>
    {title &&
      <Column justifyContent={'center'} alignItems={'center'}>
        <Title2>{!isMobile ? toFormattedText(title) : title.split('\n').join(' ')}</Title2>
        <Dots />
      </Column>
    }
    <TextContainer withoutTitle={!title}>{toFormattedLinkedText(paragraph, links)}</TextContainer>
    {showFAQ && renderFAQ()}
    {breadcrumbIntro &&
      <TextContainer withoutTitle>{toFormattedLinkedText(breadcrumbIntro, breadcrumbIntroLinks)}</TextContainer>
    }
    {itemListElement && <Breadcrumb itemListElement={itemListElement} />}
  </Container>
);

FootNote.propTypes = {
  title: PropTypes.string,
  paragraph: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ),
  breadcrumbIntro: PropTypes.string,
  breadcrumbIntroLinks: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ),
  itemListElement: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
  }).isRequired),
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  showFAQ: PropTypes.bool,
};

FootNote.defaultProps = {
  links: [],
  breadcrumbIntro: '',
  breadcrumbIntroLinks: [],
  title: '',
  itemListElement: null,
  showFAQ: false,
};

export default withAppContext(FootNote);
