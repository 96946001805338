import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors, fontSizes, Title2, Button, mobileThresholdPixels } from './styledComponents';
import Dots from './Dots';
import routesMap from '../../../Routes';
import withAppContext from '../../../withAppContext';
import { pushToLayer } from '../../../services/googleTagManager';
import callApi from '../../../services/api';

const Container = styled.div`
  padding: 100px 0px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 35px 0px 15px;
  }
`;

const Note = styled.h3`
  font-family: Libre Baskerville;
  font-size: ${fontSizes.l};
  line-height: ${fontSizes.xl};
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;

  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 30px;
    font-size: ${fontSizes.m};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const QuestionContainer = styled.div`
  width: 500px;
  margin: 20px;
  padding: 40px;
  box-shadow: -1px 13px 20px #F0F2F4;
  border-radius: 5px;
  ${({ isSEO }) => isSEO && 'cursor: pointer;'}

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 10px;
    width: calc(100vw - 60px);
  }
`;

const QuestionTitle = styled.h3`
  font-family: Libre Baskerville;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: ${colors.navy};
  margin: 0px 0px ${({ isSEO, isSelected }) => (isSEO && !isSelected) ? 0 : 30}px;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
    margin: 0px;
  }
`;

const IconQuestion = styled.div`
  cursor: pointer;
  min-width: 10%;
  display: flex;
  justify-content: flex-end;
`;

const Answer = styled.p`
  font-family: Roboto;
  font-size: 15px;
  line-height: 25px;

  color: ${colors.navy};
`;

const getQuestions = curtainsVersion => [
  'Comment se déroule le rendez-vous avec le couturier ?',
  'Est-ce que j’ai des choses à préparer avant mon rendez-vous ?',
  'Quels sont les horaires de vos couturiers ?',
  curtainsVersion
    ? 'Comment ça se passe si mes rideaux sont trop lourds ou impossibles à décrocher ?'
    : 'Les retouches peuvent-elles se faire sur place si c’est urgent ?',
  'J’habite loin, vous vous déplacez quand même ?',
  'Quels sont les délais de livraison une fois le produit retouché ?',
  'Comment je fais mon devis si je ne connais pas les retouches ?',
  'Votre service est-il plus cher qu’un retoucheur classique ?',
  'Y a-t-il des frais d’annulation ? ',
  'Que se passe-t-il en cas de soucis avec mes retouches ?',
  'Quand suis-je débité ?',
];

const getAnswers = (city, curtainsVersion) => [
  curtainsVersion
    ? `Notre couturier se déplace chez vous. Rideaux accrochés, vous déterminerez avec lui
    les retouches à effectuer en fonction des dimensions de vos fenêtres, de votre pièce et
    de vos envies évidemment. Pensez à bien prévoir le nécessaire pour ensuite décrocher les
    rideaux et les remettre au couturier. Il repartira avec pour réaliser les retouches dans
    son atelier. `
    : `Même principe que chez un retoucheur, mais à domicile !
    Notre couturier se déplace chez vous. Prenez le temps d’essayer les vêtements et
    de bien expliquer ce que vous souhaitez. Notre couturier prend les marques et mesures
    nécessaires, et repart ensuite avec vos vêtements pour réaliser les retouches dans
    son atelier.`,
  curtainsVersion
    ? `Suspendez vos rideaux 48h au moins avant le RDV pour que le tissu soit bien détendu.
      Veillez aussi à prévoir le nécessaire pour les décrocher de leur tringle. Si les rideaux
      sont lourds et difficiles à décrocher, les retouches se feront exceptionnellement sur
      place.`
    : `Uniquement un petit tas avec vos vêtements propres à retoucher.
      Si besoin, nos couturiers fournissent les basiques (boutons, élastiques, doublures, zips,
      thermocollants) dans les coloris classiques. En revanche pour les autres demandes avec
      une forme ou un coloris particulier, veillez bien à vous les procurer avant.`,
  `Nos couturiers peuvent venir chez vous de 7h à 22h toute la semaine et y compris
  le week-end ! Quand on vous dit que Tilli vous simplifie la vie ?`,
  curtainsVersion
    ? `Exceptionnellement les retouches peuvent se faire sur place. Dans ce cas, veillez
    bien à prévoir une planche à repasser, de l’espace et de la lumière pour que le
    couturier soit dans de bonnes conditions de travail et que les retouches soient réussies
    :) !`
    : `Les retouches se font dans les ateliers de nos couturiers. Pour les demandes
    urgentes, on essaiera quand même de vous arranger des retouches sur place. Tout dépendra
    de la disponibilité de nos couturiers et de la retouche à faire. Pour ça, le mieux c’est
    de nous contacter directement par mail à contact@tilli.fr. `,
  ((_) => {
    if (city === 'Paris') {
      return `Nos couturiers se déplacent dans toute l’Ile-de-France. Les frais de déplacement
        des couturiers sont de 5 € et exceptionnellement de 15 € pour les villes situées dans
        la grande couronne ou celles qui ne sont pas desservies par le métro.`;
    } else if (city === 'Lyon') {
      return `Pour le moment, nos couturiers se déplacent à Lyon, Villeurbanne et St Foy.
      Si vous habitez proche, contactez-nous.`;
    }
    return `Nos couturiers se déplacent à Paris et sa proche banlieue, Lyon, Marseille, Aix en Provence, Bordeaux, Toulouse, Lille, Rennes, Nantes, Strasbourg, Nice et Cannes.
    Si vous habitez proche d’une de ces villes, contactez-nous.`;
  })(),
  curtainsVersion
    ? `En moyenne 3 jours. Selon la complexité de la retouche ou le nombre de rideaux ou
      voilages, les délais peuvent variés. Le couturier prendra soin de vous prévenir
      évidemment. `
    : `En moyenne 3 jours. Mais si la demande est forte ou les retouches demandées plus
      complexes, ça peut prendre un peu plus de temps. Dans ce cas, le couturier vous
      prévient évidemment.`,
  `L’estimation que vous faites lors de la prise de RDV vous permet juste d’avoir
  une estimation du coût. Mais le devis n’est pas figé. Vous verrez directement avec notre
  couturier en RDV les retouches à réaliser et il réajustera l’estimation si besoin. Vous
  pouvez d’ailleurs choisir de prendre RDV en 3 clics si vous ne souhaitez pas détailler
  les retouches.`,
  curtainsVersion
    ? `Tilli applique les mêmes tarifs que les retoucheries de quartier. Pour vous donner
    une idée, l’ourlet est à 20 € sur un rideau dont la largeur est inférieure à 2m, et à 30
    € si elle est supérieure à 2m. On vous facture juste 5 € en plus pour couvrir les frais
    de déplacement de nos couturiers chez vous.`
    : `Tilli applique les mêmes tarifs que les retoucheries de quartier. Pour vous
    donner une idée, l’ourlet simple est à 12 €. On vous facture juste 5 € en plus pour
    couvrir les frais de déplacement de nos couturiers chez vous.`,
  `Les annulations sont gratuites jusqu'à 8 heures avant le rendez-vous.
  Pour dédommager les couturiers en cas d’annulation tardive, on préleve des frais
  d’annulation. 14 € pour une annulation entre 8h et 2h avant le rendez-vous, et 25 €
  lorsque c’est moins de 2h avant.`,
  `Un souci avec les retouches ? On n'y croit pas vraiment, mais au cas où on a prévu
  le coup ;) Une assurance AXA est incluse pour chaque commande et couvre tous les
  vêtements confiés à nos couturiers pendant la durée de la commande. Mais on a jamais eu
  besoin de s’en servir.`,
  `Vous serez débité après le 1er RDV. Si on vous demande vos infos bancaires avant,
  c’est uniquement pour bloquer le créneau avec nos couturiers :)`,
];

class FAQAds extends React.Component {
  constructor(props) {
    super(props);
    const { isSEO, city, curtainsVersion } = this.props;
    this.state = {
      questions: getQuestions(curtainsVersion),
      answers: isSEO ? undefined : getAnswers(city, curtainsVersion),
      selectedQuestions: [],
    };
    this.unshiftQuestionsAndAnswers = this.unshiftQuestionsAndAnswers.bind(this);
    this.selectQuestion = this.selectQuestion.bind(this);
    this.renderQuestion = this.renderQuestion.bind(this);
  }

  componentDidMount() {
    const { faqQuestions } = this.props;
    if (faqQuestions) {
      const questions = faqQuestions.map(({ question }) => question);
      const answers = faqQuestions.map(({ answer }) => answer);
      this.unshiftQuestionsAndAnswers(questions, answers);
    }
  }

  unshiftQuestionsAndAnswers(newQuestions, newAnswers) {
    const questions = [...this.state.questions];
    const answers = [...this.state.answers];
    questions.unshift(...newQuestions);
    answers.unshift(...newAnswers);

    this.setState({
      questions,
      answers,
    });
  }

  selectQuestion(question) {
    const { selectedQuestions, answers } = this.state;
    const { city, curtainsVersion } = this.props;
    if (!answers) {
      callApi(`public/faqAnswers/${city}/${curtainsVersion}`, 'get')
        .then(res => this.setState({ answers: res, selectedQuestions: [question] }));
    } else {
      let newSelectedQuestions;
      if (selectedQuestions.includes(question)) {
        newSelectedQuestions = selectedQuestions.filter(selectedQuestion => selectedQuestion !== question);
      } else {
        newSelectedQuestions = [...selectedQuestions, question];
      }
      this.setState({ selectedQuestions: newSelectedQuestions });
    }
  }

  renderQuestion(question, index) {
    const { selectedQuestions, answers } = this.state;
    const { isSEO } = this.props;
    return (
      <QuestionContainer key={question} isSEO={isSEO} onClick={() => isSEO && this.selectQuestion(question)}>
        <QuestionTitle isSEO={isSEO} isSelected={selectedQuestions.includes(question)}>
          {question}
          {isSEO && <IconQuestion>{selectedQuestions.includes(question) ? '-' : '+'}</IconQuestion>}
        </QuestionTitle>
        {(!isSEO || selectedQuestions.includes(question)) && <Answer>{answers[index]}</Answer>}
      </QuestionContainer>
    );
  }

  render() {
    const { questions } = this.state;
    const { context: { isMobile }, prefilledSteps } = this.props;
    return (
      <Container>
        <Title2>Tous les détails pratiques</Title2>
        <Dots />

        {isMobile ?
          <Column>
            {questions.map((question, index) => this.renderQuestion(question, index))}
          </Column> :
          <Row>
            <Column>
              {questions
                .map((question, index) => index % 2 === 0 && this.renderQuestion(question, index))
              }
            </Column>
            <Column>
              {questions
                .map((question, index) => index % 2 === 1 && this.renderQuestion(question, index))
              }
            </Column>
          </Row>
        }

        <Note>Bref, on a tout prévu :)</Note>
        <Link
          to={routesMap.Step1.url}
          state={prefilledSteps}
          onClick={() => pushToLayer({
            event: 'Order Funnel Started',
            from: window.location.pathname,
            from_interaction: 'FAQ_ads',
            funnel_type: 'standard',
          })}
        >
          <Button navy>Prendre rendez-vous</Button>
        </Link>
      </Container>
    );
  }
}

FAQAds.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  city: PropTypes.string,
  curtainsVersion: PropTypes.bool,
  prefilledSteps: PropTypes.shape({}),
  isSEO: PropTypes.bool,
  faqQuestions: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string,
  })),
};

FAQAds.defaultProps = {
  city: undefined,
  curtainsVersion: false,
  prefilledSteps: {},
  isSEO: false,
  faqQuestions: undefined,
};

export default withAppContext(FAQAds);
