import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors, fontSizes, Title2, Button, mobileThresholdPixels } from './styledComponents';
import Dots from './Dots';
import HowItWorksProgressBar from './HowItWorksProgressBar';
import routesMap from '../../../Routes';
import couturiereImg from '../../../assets/home/couturiere.jpg';
import curtainsImg from '../../../assets/home/curtains.jpg';
import { pushToLayer } from '../../../services/googleTagManager';
import { toFormattedBoldText } from '../../../services/formatting';

const Container = styled.div`
  padding: 100px 0px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 35px 0px 15px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 100px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 40px;
    margin-bottom: 40px;
    flex-direction: column;
  }
`;

const GreyBlock = styled.div`
  margin: auto 0px auto 20px;
  padding: ${props => props.curtainsVersion ? '50px 65px 50px 97px' : '50px 65px 50px 97px'};
  background-color: ${colors.lightGrey};
  width: 500px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
    padding: 20px;
    width: unset;
  }
`;

const Title3 = styled.h3`
  font-family: Libre Baskerville;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: ${colors.navy};
  margin: 0px 0px 30px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
    margin: 0px;
  }
`;

const Paragraph = styled.p`
  font-family: Roboto;
  font-size: 18px;
  line-height: 28px;
  ${({ noMargin }) => noMargin && 'margin: 0px;'}

  color: ${colors.navy};
`;

const Img = styled.img`
  width: 522px;
  height: 402px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100vw;
    height: unset;
  }
`;

const ACouturierAtHome = ({
  curtainsVersion, prefilledSteps, couturierAltTag, couturierBlock,
}) => {
  let text1 = '';
  let text2 = '';
  if (curtainsVersion) {
    text1 = '<bold>Nos couturiers sont professionnels, super efficaces et de bon conseils.<bold>';
    // eslint-disable-next-line max-len
    text2 = '<bold>Notre astuce à la fois green et déco ?<bold> Faites faire des housses de coussins avec les chutes de tissus. Rien ne se perd, tout se transforme chez Tilli !';
  } else {
    // eslint-disable-next-line max-len
    text1 = couturierBlock?.text1 || '<bold>Retoucheurs, modélistes, stylistes ou tailleurs,<bold> notre équipe est composée d’artisans professionnels formés dans les meilleures écoles.';
    text2 = `Laissez ${couturierBlock?.text2HasPersonalPronoun ? '' : 'vos'}`;
    // eslint-disable-next-line max-len
    text2 = `${text2} ${couturierBlock?.text2 || '<bold>tailleurs, jeans, manteaux, robes de mariées... ou tous autres vêtements<bold>'} entre des mains expertes.`;
  }

  return (
    <Container>
      <Title2>Un couturier chez vous, dès demain</Title2>
      <Dots />
      <HowItWorksProgressBar curtainsVersion={curtainsVersion} />
      <Link
        to={routesMap.Step1.url}
        state={prefilledSteps}
        onClick={() => pushToLayer({
          event: 'Order Funnel Started',
          from: window.location.pathname,
          from_interaction: 'ACouturierAtHome',
          funnel_type: 'standard',
        })}
      >
        <Button navy>Prendre rendez-vous</Button>
      </Link>

      <BottomContainer>
        <GreyBlock curtainsVersion={curtainsVersion}>
          <Title3>Des couturiers professionnels</Title3>
          <Paragraph>
            {toFormattedBoldText(text1, 500)}
          </Paragraph>
          <Paragraph noMargin>
            {toFormattedBoldText(text2, 500)}
          </Paragraph>
        </GreyBlock>
        {curtainsVersion
          ? <Img src={curtainsImg} alt="Des rideaux" />
          : <Img src={couturierBlock?.imageSrc?.publicURL || couturiereImg} alt={couturierAltTag} />
        }
      </BottomContainer>
    </Container>
  );
};

ACouturierAtHome.propTypes = {
  prefilledSteps: PropTypes.shape({}),
  curtainsVersion: PropTypes.bool,
  couturierAltTag: PropTypes.string,
  couturierBlock: PropTypes.shape({
    text1: PropTypes.string,
    text2: PropTypes.string,
    text2HasPersonalPronoun: PropTypes.bool,
    imageSrc: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
  }),
};

ACouturierAtHome.defaultProps = {
  prefilledSteps: {},
  curtainsVersion: false,
  couturierAltTag: 'Une couturière',
  couturierBlock: undefined,
};

export default ACouturierAtHome;
