import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { mobileThresholdPixels, colors, margins, ContainerBlock }
  from '../home/v2/styledComponents';

const StyledContainerBlock = styled(ContainerBlock)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0px ${margins.m} ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px ${margins.m};
    padding: 0px;
    width: 100%;
  }
`;

const LinkContainer = styled.span`
  min-width: 125px;
  margin: 0px ${margins.s};
`;

const Span = styled.span`
  font-size: 10px;
  color: ${colors.darkGrey};
  margin: 0px auto;
`;

function getParisLinks() {
  const links = [];
  const arrondissementsArray = Array.from(Array(20).keys());
  const localitiesArray =
    ['Clichy', 'Neuilly-Sur-Seine', 'Levallois-Perret', 'Boulogne-Billancourt', 'Issy-les-Moulineaux', 'Montreuil'];
  arrondissementsArray.forEach((index) => {
    const arrondissement = index + 1;
    links.push({
      to: `/retouche-vetement/paris/paris-${`0${arrondissement}`.slice(-2)}/`,
      anchor: `Retouche vêtement Paris ${arrondissement}`,
    });
  });
  localitiesArray.forEach((locality) => {
    links.push({
      to: `/retouche-vetement/paris/${locality.toLowerCase()}/`,
      anchor: `Retouche vêtement ${locality}`,
    });
  });
  return links;
}

function getMarseilleLinks() {
  const links = [];
  const arrondissementsArray = [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  arrondissementsArray.forEach((arrondissement) => {
    links.push({
      to: `/retouche-vetement/marseille/marseille-${`0${arrondissement}`.slice(-2)}/`,
      anchor: `Retouche vêtement Marseille ${arrondissement}`,
    });
  });
  return links;
}

function getLyonLinks() {
  const links = [];
  const arrondissementsArray = Array.from(Array(9).keys());
  arrondissementsArray.forEach((index) => {
    const arrondissement = index + 1;
    links.push({
      to: `/retouche-vetement/lyon/lyon-${`0${arrondissement}`.slice(-2)}/`,
      anchor: `Retouche vêtement Lyon ${arrondissement}`,
    });
  });
  return links;
}

function getLinks({ city, locality, arrondissement }) {
  if (locality || arrondissement) return [];
  if (city === 'Paris') { return getParisLinks(); }
  if (city === 'Marseille') { return getMarseilleLinks(); }
  if (city === 'Lyon') { return getLyonLinks(); }
  return [];
}

class FooterSEOLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = { links: getLinks(props) };
  }

  render() {
    const { links } = this.state;
    return (
      <StyledContainerBlock>
        {links.map(link =>
          <LinkContainer key={link.to}>
            <Link
              to={link.to}
              style={{ textDecoration: 'none' }}
            >
              <Span>{link.anchor}</Span>
            </Link>
          </LinkContainer>)}
      </StyledContainerBlock>
    );
  }
}

FooterSEOLinks.propTypes = {
  city: PropTypes.string.isRequired,
  locality: PropTypes.string,
  arrondissement: PropTypes.string,
};

FooterSEOLinks.defaultProps = {
  locality: null,
  arrondissement: null,
};

export default FooterSEOLinks;
